<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">
        {{ title }}
      </h1>
      <div class="panel">
        <div class="columns" style="margin: 1.2em;">
          <div class="column is-2">
            <b-datepicker
                v-model="fromdate"
                placeholder="ຈາກວັນທີ"
                :locale="this.getDateLocale"
                icon="calendar-today"
            >
            </b-datepicker>
          </div>
          <div class="column is-2">
            <b-datepicker
                v-model="todate"
                placeholder="ຫາວັນທີ"
                :locale="this.getDateLocale"
                icon="calendar-today"
            >
            </b-datepicker>
          </div>
          <div class="column is-3">
            <b-field horizontal>
              <b-input
                  v-model="keyword"
                  placeholder="ຊອກຫາ ດ້ວຍ ລະຫັດ, ຊື່ ຫຼື ເບີໂທ"
                  @keyup.native.enter="search()"
              />
              <p class="control" v-if="isAndroid">
                <b-button
                    icon-left="barcode"
                    class="button is-primary"
                    @click="scanBarcode"
                ></b-button>
              </p>
            </b-field>
          </div>
          <div class="column is-2">
            <button class="button" @click="search()">
              <b-icon icon="magnify" />
            </button>
          </div>
        </div>
      </div>

      <card-component class="has-table has-mobile-sort-spaced ">
        <b-table
            :loading="isLoading"
            :paginated="perPage < models.length"
            :per-page="perPage"
            :striped="true"
            :hoverable="true"
            default-sort="name"
            :data="models"
            :current-page.sync="currentPage"
            :pagination-rounded="true"
            narrowed
        >
          <b-table-column label="#" v-slot="props">
            {{ props.index+(currentPage>1?(perPage*currentPage-perPage):0)+1 }}
          </b-table-column>
          <b-table-column
              label="ວັນທີ່ສົ່ງ"
              field="code"
              sortable
              v-slot="props"
          >
            {{ props.row.created_date | datetime }}
          </b-table-column>
          <b-table-column label="ລະຫັດ" field="code" sortable v-slot="props">
            {{ props.row.code }}
          </b-table-column>
          <b-table-column
              label="ສາຂາຕົ້ນທາງ"
              field="receivername"
              sortable
              v-slot="props"
          >
            {{ props.row.branch_branchToshipment_from_branch_id.name }}
          </b-table-column>
          <b-table-column
              label="ຜູ້ສົ່ງ"
              field="sendername"
              sortable
              v-slot="props"
          >
            {{ props.row.sendername }}
          </b-table-column>
          <b-table-column
              label="ຜູ້ຮັບ"
              field="receivername"
              sortable
              v-slot="props"
          >
            {{ props.row.receivername }}
          </b-table-column>
          <b-table-column
              label="ຄ່າຝາກ"
              field="amount"
              sortable
              v-slot="props"
              cell-class="has-text-right"
          >
            {{ props.row.amount | formatnumber }}
          </b-table-column>
          <b-table-column
              label="ມື້ທີ່ຖືກອາຍັດ"
              field="is_receiver_pay"
              sortable
              v-slot="props"
              cell-class="has-text-centered"
          >
         {{ props.row.hold_date | date }}
          </b-table-column>          <b-table-column
              label="ເຫດຜົນການອາຍັດ"
              field="is_receiver_pay"
              sortable
              v-slot="props"
              cell-class="has-text-centered"
          >
         {{ props.row.hold_reason }}
          </b-table-column>
          <b-table-column
              custom-key="actions"
              cell-class="is-actions-cell"
              v-slot="props"
          >
          <div class="buttons is-right">
            <b-tooltip label="ສົ່ງກັບເຄື່ອງຖືກອາຢັດ" position="is-left" type="is-warning" 
            v-if=" !'return_to_source,forwarded'.includes(props.row.status) ">
              <b-button 
                  class="is-warning button is-small"
                  @click="rejectShipment(props.row.code)"
                  icon-left="undo-variant" />
              </b-tooltip>

              <b-tooltip label="ສົ່ງຕໍ່ເຄື່ອງຖືກອາຢັດ" position="is-left" type="is-danger" 
              v-if=" !'return_to_source,forwarded'.includes(props.row.status) " >
              <b-button  
                  class="is-danger button is-small"
                  @click="forwardShipment(props.row.code)"
                  icon-left="share" />
             </b-tooltip>
             <b-tooltip label="ເບີ່ງລາຍລະອຽດ" type="is-info"
                         position="is-left">
                <b-button
                    class="is-small button is-info"
                    @click="view(props.row.code)"
                    icon-left="eye"
                >
                </b-button> </b-tooltip>
             </div>
          </b-table-column>
          <empty-table v-if="models.length == 0" :isLoading="isLoading" />
        </b-table>
      </card-component>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import CardComponent from "@/components/CardComponent";
import EmptyTable from "../components/EmptyTable.vue";
//import Swal from "sweetalert2";
import {get} from "vuex-pathify";
import moment from "moment";

export default {
  name: "HoldingShipment",
  components: { EmptyTable, CardComponent },
  computed: {
    user: get("user"),
    getDateLocale() {
      return "en-GB";
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      title: "ລາຍການເຄື່ອງຖືກອາຍັດ",
      model: null,
      models: [],
      isShowModal: false,
      isLoading: false,
      keyword: null,
      fromdate: new Date(),
      todate: new Date(),
    };
  },
  mounted() {
    this.search();
  },
  watch: {
    models: function () {
      this.isLoading = false;
    },
  },
  methods: {
    view(code) {
      this.$router.push({ path: "/shipment/" + code });
    },
    rejectShipment(code){
      this.$router.push({ path: `/updateshipment/${code}` , query: { command:'REJECT' } });
    },
    forwardShipment(code){
      this.$router.push({ path: `/updateshipment/${code}` , query: { command:'FORWARD' }});
    },
    search() {
      this.isLoading = true;
      const fdate = moment(this.fromdate).format("YYYY-MM-DD");
      const tdate = moment(this.todate).format("YYYY-MM-DD");
      if(this.keyword==='') this.keyword=null;
      axios.get(`shipments/holdinglist/${fdate}/${tdate}/${this.keyword}`).then((r) => {
        this.models = r;
      });
    },

    find(barcode) {
      console.log("finding barcode: ", barcode);
      axios
          .get(`shipment/findholding/${barcode}`)
          .then((parcel) => {
            this.$router.push({
              path: "/shipment/" + parcel.code,
              params: { propShipment: this.shipment },
            });
          })
          .catch((e) => {
            console.log(e);
            this.$buefy.snackbar.open({
              message: "Barcode not found",
              queue: false,
            });
          });
    },

    scanBarcode() {
      this.$buefy.snackbar.open({
        message: "Scan barcode",
        queue: false,
      });

      try {
        return window.AndroidApp.scanBarcode("dlBarcodeScanned");
      } catch (e) {
        console.log(e);
      }
    },

    toDeliverParcelsBarcodeScanned(barcode) {
      this.$buefy.snackbar.open({
        message: `Scan barcode : ${barcode}`,
        queue: false,
      });
      if (barcode !== undefined && barcode.length > 0) {
        this.find(barcode);
      }
    },

  },
  beforeMount() {
    //Create interface between Webview and Native application
    window["MyWebView"] = {
      components: this,
      dlBarcodeScanned: (barcode) =>
          this.toDeliverParcelsBarcodeScanned(barcode),
    };
  },
};
</script>